hr {
  border-bottom: 1px solid #eaeaea;
  margin: 2rem auto;
}

.hr {
  border-bottom: rem-calc(4px) solid $primary-color;
  width: 25%;
  margin: 2rem auto;

  &--full {
    width: 100%;
  }

  &--half {
    width: 50%;
  }

  &--left {

    margin-left: 0;
  }

  &--right {
    @include breakpoint(medium) {
      margin-right: 0;
    }
  }
}

.dotted {
  border-style: dashed;
  margin: 2rem 0;
}
