.blog-post {
  &--cs {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  &__header {
    padding: 50% 0 1rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &:hover {
      box-shadow: 0 12px 60px rgba(10, 10, 10, .3);
      @include overlay(90deg, $white, .15, $white, .15, $white, .15);
    }
  }

  &__title {
    font-weight: 400;
  }

  &__content {
    padding: 1rem;
    background-color: $light-gray;
  }
}

.post-img {
  width: 100%;
}

.widget {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__h {
    padding: .5rem;
    background-color: $primary-color;
    color: $white;
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      background-color: $light-gray;
      padding: .5rem 1rem;
      margin-bottom: 1rem;

      a {
        color: inherit;
      }
    }
  }
}

.single-post-header {
  padding: 2rem 0;

  @include breakpoint(medium) {
    padding: 4rem 0;
  }
}

.single {
  &__content {
    font-size: 17px;
    margin-bottom: 2rem;

    p {
      line-height: 2;
    }
  }
}

progress {
  position: fixed;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 6px;

  /* Reset the appearance */
  appearance: none;

  /* Get rid of the default border in Firefox/Opera. */
  border: none;

  /* Progress bar container for Firefox/IE10+ */
  background-color: transparent;

  /* Progress bar value for IE10+ */
  color: $primary-color;

  &::-moz-progress-bar {
    background-color: $primary-color;
  }

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: $primary-color;
  }
}

.social-share {
  .button {
    
  }
  .icon-facebook {
    color: #405894;
    &:hover {
      color: scale-color(#405894, $lightness: -35%);
    }
  }
  
  .icon-twitter {
    color: #50ABE8;
    &:hover {
      color: scale-color(#50ABE8, $lightness: -35%);
    }
  }
  
  .icon-linkedin {
    color: #0077AF;
    &:hover {
      color: scale-color(#0077AF, $lightness: -35%);
    }
  }
  
  .icon-pinterest {
    color: #D11632;
    &:hover {
      color: scale-color(#D11632, $lightness: -35%);
    }
  }
}

.related-posts {
  padding: 2rem 0 3rem;

  &__title {
    background: $primary-color;
    color: $white;
    margin: 0;
    padding: .5rem 1rem;
  }
}