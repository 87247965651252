.section {
  padding: 2rem 0;
  overflow: hidden;

  @include breakpoint(large) {
    padding: 4rem 0;
  }

  @include breakpoint(xxlarge) {
    padding: 6rem 0;
  }

  &--primary {
    background-color: $primary-color;
    color: $white;

    p,
    .heading {
      color: $white;
    }
  }

  &--light {
    background-color: $light-gray;
  }

  &--glyph {
    background: url('../images/logos/octoclean-glyph-a.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    background-position: 0 center;
  }

  img.icon {
    max-width: rem-calc(98);
    padding: 1rem;
  }

  i.icon {
    color: $black;
    font-size: rem-calc(64);
  }

  &--icons {
    h6 {
      color: $primary-color;
    }

    li {
      font-size: $small-font-size;
    }

    .icon-wrap {
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 50%;
      display: inline-block;
      border: 3px solid transparent;

      &:hover {
        border-color: $primary-color;
        transition: all .15s ease-in-out;

        .icon {
          transform: scale(.85);
          transition: all .15s ease-in-out;
          z-index: 9;
        }
      }
    }
  }

  &__h {
    margin-bottom: 2rem;

    &--white {
      color: $white;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 2rem;

    // @include breakpoint(large) {
    //   margin-bottom: 4rem;
    // }

    &--left {
      text-align: left;
    }
  }

  &__body {
    padding: 1rem .75rem;
  }

  &__image {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: rem-calc(200);

    @include breakpoint(large) {
      max-width: rem-calc(300);
    }

    &--shadow {
      @include shadow;
    }
  }

  &__text {
    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: rem-calc(200);

      @include breakpoint(large) {
        max-width: rem-calc(300);
      }
    }
  }

  &__footer {
    text-align: center;
    margin-top: 4rem;
  }

  &--industries {
    .icon {
      width: 140px;
      padding: 1rem;
    }

    .fa-hospital,
    .fa-school,
    .fa-building {
      padding: 1rem;
      font-size: 4.5rem;
      color: $black;
    }

    a {
      h3 {
        color: $primary-color;
      }
    }

    .wrap {
      display: inline-block;
    }
  }
}