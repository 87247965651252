.brand {
  font-family: $header-font-family;
  font-weight: 300;

  b {
    font-weight: 600;
  }
}

img {
  width: auto;
}

.text-primary {
  color: $primary-color;
}

.text-white {
  color: $white;
}

.text-body {
  color: $body-font-color !important;
}