.footer {
  background-color: $secondary-color;
  border-top: 1px solid $medium-gray;
  color: $white;
  padding-top: 1rem;

  @include breakpoint(medium) {
    padding-top: 2rem;
  }

  @include breakpoint(large) {
    padding-top: 3rem;
  }

  p,
	h1,h2,h3,h4,h5,h6 {
		color: $white;
  }
  
  hr {
    margin: 1rem auto;
  }

  li {
    margin-bottom: 1rem;
  }

  &__grid {
    @include xy-grid-container;
    @include xy-grid;
    padding: rem-calc(40) 1rem;
  
    section {
      @include xy-cell();
      @include breakpoint(large) {
        @include xy-cell(auto);
      }
    }
  
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
}

.social {
  margin-top: 1rem;
  i {
      padding: .5rem;
      cursor: pointer;
      color: $white;
      font-size: 1.8rem;
      font-weight: 800;
      
      &.fa-facebook,
      &.fa-facebook-f {
          &:hover {
              color: #405894;
          }
      }
  
      &.fa-twitter {
          &:hover {
              color: #50ABE8;
          }
      }
  
      &.fa-linkedin,
      &.fa-linkedin-in {
          &:hover {
              color: #0077AF;
          }
      }
  
      &.fa-pinterest {
          &:hover {
              color: #D11632;
          }
      }
  
      &.fa-instagram {
          &:hover {
              color: #2e5e86;
          }
      }

      &.fa-youtube {
          &:hover {
              color: #ff0000;
          }
      }
  }
}

.copyright {
	text-align: center;
	background-color: $dark-gray;
	padding: .5rem 0;

	p.copyright__text {
		font-size: .85rem;
		font-weight: 300;
		color: $light-gray;
		margin: 0;
	}
	
	a.copyright__text--muted {
		font-size: .7rem;
		color: $success-color;
	}
}
