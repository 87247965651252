.button {
  border-radius: rem-calc(45px);
  // min-width: rem-calc(100);
  margin: 1rem 0;
  
  &--min {
    min-width: rem-calc(160px);
  }
}

.sbi_load_btn {
  @extend button;
}

.cancel-button {
  margin-top: 1rem;
}

.blog-button-tag {
  border-radius: 0;
  margin: 0;
  
  &:not(:last-child) {
    margin-right: 5px;
  }

  @include breakpoint(small down) {
    font-size: .8rem !important;
  }
}

.button-inverted {
  @include button;
  border: 1px solid $white;

  &:hover {
    background-color: $white;
    color: $primary-color;
    border-color: $primary-color;
  }
}