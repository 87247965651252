// Page header
.front-hero {
  overflow: hidden;

  .marketing {
    @include xy-grid-container(85%);
    @include xy-grid;
  }

  @include breakpoint(small) {
    background-image: linear-gradient(to right, rgba(#000, .4), rgba(#000, .05), rgba(#000, 0)), url('../images/home/octoclean-room-cleaning.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: rem-calc(65%) 0;
    height: 100vh;
    position: relative;
    text-align: left;
  }

  @include breakpoint(medium) {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: rem-calc(600);
  }

  @include breakpoint(large) {
    min-height: rem-calc(800);
  }

  .tagline {
    color: $white;
    z-index: 2;

    &--light {

      h1,
      h2,
      p {
        color: $white;
        text-shadow: 1px 1px 5px rgba(0,0,0,1);
      }
    }

    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(medium) {
      @include xy-cell(8);
      padding-top: 7rem;
    }

    @include breakpoint(large) {
      @include xy-cell(7);
      padding-top: 8rem;
    }

    @include breakpoint(xlarge) {
      @include xy-cell(8);
      padding-top: 30%;
    }
  }

  a.button {
    margin-top: 1rem;
  }
}

// Sections
.industries {
  background-color: #F4F4F4;
  padding-left:  1rem;
  padding-right: 1rem;
}

.partner-logo {
  height: 60px;
  max-width: 100%;
  object-fit: contain;
}