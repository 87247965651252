.contact-tabs-wrap {
    .tabs {
        display: flex;
        flex-flow: column nowrap;

        @include breakpoint(medium) {
            flex-direction: row;
        }

        .tabs-title {
            text-align: center;
            flex: 1 0 auto;

            &:hover,
            &.is-active {
                border-bottom: 1px solid $primary-color;

                a {
                    color: $primary-color;
                }
            }

            a {
                color: $body-font-color;
            }
        }
    }

    .tabs-panel {
        background-color: rgba($light-gray, 0.5);

        @include breakpoint(medium) {
            padding: 2rem;
        }

        @include breakpoint(large) {
            padding: 3rem;
        }
    }
}