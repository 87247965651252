.hbspt-form {
    fieldset {
        max-width: 100% !important;
    }

    .form-columns-3 {
        .hs-form-field:last-child {
            .input {
                margin-right: 0 !important;
            }
        }
    }

    .hs-input {
        width: 100% !important;
    }
    
    input[type="checkbox"],
    input[type="radio"] {
        width: auto !important;
    }
    
    .inputs-list {
        li {
            list-style-type: none;
        }
    }
}