.main-container {
  @include xy-grid-container;
}

.main-container-full {
  @include xy-grid-container(100%);
}

.main-container-narrow {
  @include xy-grid-container(50rem);
}

.main-grid {
  @include xy-grid;
  justify-content: center;

  &.sidebar-left {

    // Place the sidebar below main content on small screens ...
    @include breakpoint(small) {
      .main-content {
        order: 1;
      }

      .sidebar {
        order: 2;
      }
    }

    // ... and to the left on medium-up screens, when using the sidebar-left template
    @include breakpoint(medium) {
      .main-content {
        order: 2;
      }

      .sidebar {
        order: 1;
      }
    }
  }

  // Full width template
  .main-content-full-width {
    @include xy-cell(12);
    margin: 0;
    width: 100%;
  }

  // Default template
  .main-content {
    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(large) {
      @include xy-cell(8);
    }
  }

  .sidebar {
    margin-bottom: 2rem;

    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(large) {
      @include xy-cell(4);
    }

  }
}

.cta {
  text-align: center;
  background-color: $light-gray;
  padding: 4rem 0;

  &__img {
    width: auto;
    max-width: rem-calc(160);
    margin-bottom: 1rem;
  }

  &__text {
    margin: 1rem 0 2rem 0;
  }

  #sad-sammy {
    display: none;
  }
}

.features {
  margin: 4rem auto;

  .feature {
    margin-bottom: 1rem;
    height: 100%;
    overflow: auto;

    @include breakpoint(medium) {
      margin-bottom: 2rem;
    }

    &__icon {
      @include breakpoint(medium) {
        float: left;
      }

      img {
        width: rem-calc(70px);

        @include breakpoint(medium) {
          display: block;
        }
      }
    }

    &__content {
      @include breakpoint(medium) {
        margin-left: rem-calc(92px);
      }
    }
  }
}

.benefits {
  .image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: rem-calc(300px);

    &--benefits {
      background-image: url('../images/home/octoclean-wiping-disinfecting.jpg');
    }
  }

  &__content {
    padding: 4rem 2rem;

    @include breakpoint(small down) {
      text-align: center;
    }

    @include breakpoint(medium) {
      padding: 5rem 3rem;
    }

    @include breakpoint(large) {
      padding: 6rem 4rem;
    }

    @include breakpoint(xlarge) {
      padding: 6rem;
    }

    @include breakpoint(xxlarge) {
      padding: 8rem;
    }
  }

  .card {
    border: 0;
    text-align: center;
  }
}

.checkmark-icon {
  width: rem-calc(60);
  margin: 0 auto;
}

.wp-block-columns {
  // @include flex-align(center, middle);
  padding-bottom: 2rem;

  @include breakpoint(large) {
    padding-bottom: 6rem;
  }

  @include breakpoint(small down) {
    &:nth-child(even) {
      .wp-block-column {
        &:nth-child(odd) {
          order: 2;
        }
      }
    }
  }
}

.wp-block-column {
  margin: 0;
}

.block-cover,
.block-cover-image {
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: 50%;
  min-height: 430px;
  width: 100%;
  margin: 0 0 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.feature-card {
  &__list {
      list-style-type: none;
      text-align: left;
      display: inline-block;
      li {
          margin-bottom: 1rem;

          &::before {
              content:"\2713\0020";
          }
      }
  }

  &__h {
      margin: 1rem 0;
  }
}

.boxes {  
  a {
    color: $secondary-color;
    position: relative;
    display: block;
    padding: 1.25rem 3rem 1.25rem 1rem;
    border: none;
    border-bottom: 0;
    font-size: 1.125rem;
    line-height: 1;

    &:hover {
      color: $primary-color;
    }
  }

  img {
    width: 100%;

    &:hover {
      box-shadow: 0 12px 60px rgba(10, 10, 10, .3);
      @include overlay(90deg, $white, .15, $white, .15, $white, .15);
    }
  }

  .accordion-title {
    &:first-child {
      &::before {
        right: 0;
        content: none;
      }
    }
  }
}

.glyph-bg {
  background: url('../images/logos/octoclean-glyph-a.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.responsive-embed {
	padding-bottom: 56.25%;
	max-width: 100%;
  margin: 0 auto;

  @include breakpoint(medium) {
    max-width: 80%;
    padding-bottom: 45.25%;
  }
}

.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  /* Then style the iframe to fit in the container div with full height and width */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}


.icon-step {
  max-width: 420px;
    position: relative;
    margin: 20px auto;

  &__icon {
    height: 45px;
    width: 45px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    padding-left: 80px;
  }

  &__link {
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba($secondary-color, 0.25);
    padding-bottom: 4px;
  }
}

.post-type-tag {
  background-color: $secondary-color;
  color: $white;
  font-weight: 500;
  margin-right: .25rem;
  padding: .25rem .5rem;
}

.post-meta {
  p {
    margin: .5rem 0;
  }
}