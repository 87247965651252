.pagination {
    text-align: center;
    
    .current {
        padding: rem-calc(8 16);
        background: $light-gray;
        color: inherit;
        border: rem-calc(2) solid $primary-color;
    }

    a, button {
        padding: rem-calc(8 16);
    }

    @include breakpoint(small only) {
        .page-numbers {
            &:not(:first-child) {
                &:not(:last-child) {
                    display: none;
                }
            }
        }
    }
}