// Animation
.inv {
  opacity: 0;
  transform: scale(0.8);
}

.section {
  transition: all 1s ease-in-out;
}

.section.vis {
  opacity: 1;
  transform: none;
}