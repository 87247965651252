.cs_company-logo {
    max-width: 240px;
    margin-bottom: 1rem;
}

.cs_about {
    background-color: $primary-color;
    color: $white;
    margin-bottom: 3rem;

    .about-info-content {
        color: $white;
        max-width: rem-calc(600);
        margin: 0 auto;

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
            color: $white;
        }
    }

    .card {
        width: auto;
        max-width: rem-calc(300);
        height: 100%;

        @include breakpoint(medium down) {
            margin: 0 auto;
        }

        .about-image {
            width: 120px;
            margin: 0 auto;
        }
    
        ul {
            list-style-type: none;
        }
    }
}

.related-cs {
    border: 1px solid $primary-color;
    height: 100%;
    width: rem-calc(300px);
    display: flex;
    flex-direction: column;

    &__logo {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        height: rem-calc(100px);
        width: 100%;
        margin: 0 0 1.5em;
        overflow: hidden;
    }

    &__content {
        background-color: $light-gray;
    }
}

.cs-hero {
    &__logo-group {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      gap: 2rem;
    }
}

.swiper-container-cs {
    max-width: 648px;

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-pagination-bullets {
        bottom: 0;
    }

    .swiper-pagination-bullet-active {
        background: $white;
    }

    .swiper-button-next {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23fff'%2F%3E%3C%2Fsvg%3E");
    }

    .swiper-button-prev {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23fff'%2F%3E%3C%2Fsvg%3E");
    }
}
  
.cs-bubble {
    background-color: $white;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &__logo {
        width: rem_calc(100px);
        height: auto;
      }
}

.swiper-container-wrap {
    width: 100%;
    padding-right: 50px;
    padding-left: 50px;
    margin-right: auto;
    margin-left: auto;
    //make-container
    max-width: 1330px;
    position: relative;
    list-style: none;
    z-index: 1;
    // @media all and (max-width: ($md - 1)) {
    //     padding: 0;
    // }
}

.swiper-container-featured_cs {
    padding-top: 2rem;

    .swiper-slide {
        height: auto;
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-pagination-bullets {
        bottom: 0;
    }
}

.featured-cs {
    height: 100%;
    border-color: $success-color;
    // max-width: rem_calc(300px);
    margin: 0 auto;

    &__top {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        width: rem_calc(100px);
    }
}