.site-header {
  @include shadow;
  z-index: 9;
  position: fixed;
  position: sticky;
  top: 0;
  width: 100%;

  a {
    color: $secondary-color;
    font-size: rem-calc(15px);
    &:hover {
      color: $primary-color;
    }
  }

  .header-notice {
    height: rem-calc(40);
    background-color: $primary-color;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  
    a {
      color: $white;
      font-weight: 500;
      @include breakpoint(small down) {
        font-size: .85rem;
      }
  
      i {
        vertical-align: middle;
        font-weight: 500;
      }
    }
  }
}

.logged-in {
  .site-header {
    top: 46px;

    @media screen and (min-width: 783px) {
      top: 32px;
    }
  }
}

.site-title {
  margin: 0;

  &--logo {
    line-height: 0;
  }
}

// Navigation breakpoints
.mobile-menu,
.site-title-bar {
  @include hide-for(xlarge);
}

.desktop-menu,
.site-navigation .top-bar-left {
  @include show-for(xlarge);
  margin-left: auto;
}

.site-navigation .top-bar-right {
  text-align: center;
  padding: .25rem 0;
  
  .button {
    margin: 0;
    border-radius: 0;
    text-transform: none;
    box-shadow: none;
    border: 0;
  }

  span {
    font-size: 14px;
  }

  .faq-button {
    font-size: rem-calc(24px);
    padding: 1.1rem;
    &:hover {
      color: $primary-color;
    }
  }
}

.desktop-menu {
  padding: 0 1rem;
}

// Site title
.site-desktop-title a {
  font-weight: bold;

  img {
    @include breakpoint(large down) {
      width: 180px;
    }
  }
}

.mobile-menu,
.mobile-off-canvas-menu,
.mega-menu-wrap {
  a {
    color: $secondary-color;
    font-size: rem-calc(15px);
  }

  .info {
    margin: 2rem 0 1rem;
    text-align: center;

    .button {
      color: $white;
      display: inline-block;
      border-radius: 0;
      margin-bottom: 0;
      text-transform: none;
    }

    span {
      font-size: 14px;
    }
  }
}

.custom-logo {
  max-width: rem-calc(240);
}

.mobile-off-canvas-menu {
  .close-menu {
    padding: 1rem .5rem;
    text-align: right;
  }

  .faq-button {
    font-size: rem-calc(20px);
    padding: .6rem;
  }
}

.mobile-menu {
  padding: 1rem 0;
}

// WP post navigation
.post-navigation {
  @include clearfix;
}

.nav-previous {
  float:left;
}

.nav-next {
  float:right;
}

.menu.vertical {
  padding: 0 1rem;
}

.is-dropdown-submenu {
  min-width: 210px;
}

// add hollow dropdown arrows
.dropdown.menu > li.is-dropdown-submenu-parent > a:after,
.submenu-toggle::after {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 0.45rem;
  left: 0.5rem;
  position: relative;
  top: 0.15rem;
  vertical-align: top;
  width: 0.45rem;
  transform: rotate(135deg);
  border-color: unset;
}

.submenu-toggle[aria-expanded='true']::after {
  transform: rotate(135deg);
}

.is-dropdown-submenu-parent a::after {
  transform: rotate(45deg);
  margin: 0;
}

.menu-button {
  i {
    font-size: 2rem;
    padding: .5rem;
  }
}

.menu-item.is-active.is-submenu-item > a {
  background: none;
  color: $primary-color;
}

// prevent FUOC
// .no-js {
//   .desktop-menu {
//     li {
//       &:nth-child(1) {
//         a {
//           width: 93px;
//         }
//       }
//       &:nth-child(2) {
//         a {
//           width: 104px;
//         }
//       }
//       &:nth-child(4) {
//         a {
//           width: 124px;
//         }
//       }
//     }
//   }
// }

// add breakpoint so additional mainn nav item fits
@media screen and (max-width:1285px) and (min-width:1200px) {
  .dropdown.menu > li > a {
    padding: 0.7rem;
  }

  .top-bar-title {
    margin-right: 0;
  }

  .desktop-menu {
    padding-left: .5rem;
  }
}