@mixin overlay($direction: 90deg, $color-one: $black, $alpha-one: 0.8, $color-two: $black, $alpha-two: 0.5, $color-three: $black, $alpha-three: 0) {
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient( $direction, rgba($color-one, $alpha-one), rgba($color-two, $alpha-two), rgba($color-three, $alpha-three) );
    }
}