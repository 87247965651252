/*Comment Output*/
.comments {
    margin: 4rem 0;
}

.respond {
    margin: 4rem 0;
}

h3 {
    &#comments {
        margin-bottom: 1rem;
    }
}

.comment-list {
   padding: 0;
   margin: 0;
   .children {
       padding: 0 0 0 5%;
       .comment-body {
           background-color: $light-gray;
       }
   }
   li {
       list-style: none;
       p {
           //margin: 0;
       }
       article {
           border-left: 4px solid $success-color;
       }
   }
}

.comment-body {
   padding: 1rem;
   border-bottom: 1px solid #eee;
   border-right: 1px solid #eaeaea;
   margin-bottom: 1rem;
}

.comment-content {
   margin: 1rem 0;
   padding: 1rem;
   background-color: #eee;
   border: 1px solid #ccc;
}

.navigation {
   a {
       display: inline-block;
       margin-bottom: 1rem;
       text-transform: uppercase;
       font-weight: 600;
   }
}

.comment-reply-title {
   text-transform: uppercase;
}

.comment-respond, {
//    padding: 1.5rem;

//    input[type=text],
//    input[type=email],
//    input[type=password],
//    input[type=url],
//    select,
//    textarea {
//    }

   input[type=checkbox] {
       //@extend .form-check-input;
       margin-top: .5rem;
   }
   .comment-form-cookies-consent {
       label {
           display: inline;
       }
   }
}

/*.comment-list .alt {
   background: red;
}*/

// .comment-list .odd {}
// .comment-list .even {}

// .comment-list .thread-alt {}
// .comment-list .thread-odd {}
// .comment-list .thread-even {}

// .comment-list li ul.children .alt {}
// .comment-list li ul.children .odd {}
// .comment-list li ul.children .even {}

// .comment-list .vcard {}
// .comment-list .vcard cite.fn {}
// .comment-list .vcard span.says {}
// .comment-list .vcard img.photo {}
// .comment-list .vcard img.avatar {}
// .comment-list .vcard cite.fn a.url {}

// .comment-list .comment-meta {} 
// .comment-list .comment-meta a {}
// .comment-list .commentmetadata {}
// .comment-list .commentmetadata a {}

// .comment-list .parent {}
// .comment-list .comment {}

// .comment-list .pingback {}
// .comment-list .bypostauthor {}
// .comment-list .comment-author {}
// .comment-list .comment-author-admin {}

// .comment-list {}
// .comment-list li ul {}
// .comment-list li ul.children li {}
// .comment-list li ul.children li.alt {}
// .comment-list li ul.children li.byuser {}
// .comment-list li ul.children li.comment {}
// .comment-list li ul.children li.depth-{id} {}
// .comment-list li ul.children li.bypostauthor {}
// .comment-list li ul.children li.comment-author-admin {}

// #cancel-comment-reply {}
// #cancel-comment-reply a {}