.testimonials {
    background-color: #fdfdfd;
  
    @include breakpoint(small down) {
      text-align: center;
    }
  }
  
  .testimonial {
    @include card-container();
    padding: 1.5rem;
    height: 100%;
    border: 0;
    margin: 0;
    background-color: transparent;
  
    @include breakpoint(large) {
      padding: 0 2rem;
    }
  
    &__image {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: rem-calc(200);
  
      @include breakpoint(large) {
        max-width: rem-calc(300);
      }
    }
  
    &__header {
      height: rem-calc(120);
      width: 100%;
      margin: 0 auto;
      position: relative;
      text-align: center;
    }
  
    &__logo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-height: rem-calc(200);
      max-width: rem-calc(200);
      height: 100%;
    }
  
    &__body {
      @include card-section;
  
      @include breakpoint(medium only) {
        padding: 1rem 0;
      }
    }
  
    &__footer {
      padding: 0 1rem;
  
      p {
        font-weight: 600;
      }
  
      small {
        font-style: italic;
      }
    }
  }
  