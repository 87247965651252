.grid-container {
  &--header {
    @include breakpoint(medium) {
      @include xy-grid-container(80%);
    }
  }
}

.featured-hero {
  background-size: cover;
  background-position: center 25%;
  background-repeat: no-repeat;
  position: relative;
  min-height: 80vh;
  @include overlay(to top, #000, .7, #000, .25, #000, 0);

  @include breakpoint(medium) {
    height: 90vh;
  }

  &--video {
    height: auto;
    min-height: auto;
    @include overlay(to top, #000, .75, #000, .5, #000, .25);
  }

  &__container {
    @include breakpoint(medium) {
      @include xy-grid-container(80%);
    }
  }
}

.page-intro {
  color: $white;
  position: absolute;
  bottom: 5%;
  z-index: 2;
  display: inline-block;
  padding: 1rem;

  @include breakpoint(xlarge) {
    bottom: 15%;
  }

  &__title {
    color: $white;
    margin: 0;
    text-shadow: 2px 2px 1px $black;
  }

  &__subtitle {
    color: $white;
    font-weight: 400;
    margin: 1rem 0 0 0;
    text-shadow: 2px 2px 1px $black;
    line-height: 1.4;
  }
}

.page-header {
  padding: 3rem 0;
  // background-image: linear-gradient(to right, rgba(#fff, 1), rgba(#fff, .5), rgba(#fff, .25)), url('../images/window-cleaning.jpg');
  background-color: $light-gray;
  background-position: 65% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @include breakpoint(medium) {
    padding: 6rem 0;
  }

  &--blog {
    padding: 1rem 0;

    @include breakpoint(medium) {
      padding: 2rem 0;
    }
  }

  &__title {
    margin: 0;
  }
}

.featured-video {
  width: 100%;
  margin: 0 auto;

  @include breakpoint(large) {
    max-width: 75rem;
    padding: 3rem;
  }

  iframe {
    z-index: 1;
  }
}
