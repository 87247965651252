@charset "utf-8";

// Foundation
@import "settings";
@import "foundation";
@import "motion-ui";
@import 'aos';

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

@include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Vendor
@import "swiper";
@import "fontawesome";
@import "light";
@import "brands";
@import "solid";

// Global
@import "global/mixins";
@import "global/base";
@import "global/accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/wp-overrides";
@import "global/gutenberg";
@import "global/content";
// @import "global/helper";

// Modules
@import "modules/featured-image";
@import "modules/navigation";
@import "modules/footer";
@import "modules/editor-style";
@import "modules/blog";
@import "modules/testimonials";
@import "modules/before-after";
@import "modules/staff";

// Components
@import "components/buttons";
@import "components/cards";
@import "components/dividers";
@import "components/links";
@import "components/comments";
@import "components/slider";
@import "components/animation";
@import "components/pagination";
@import "components/forms";
@import "components/sections";
@import "components/tabs";

// Templates
@import "templates/front";
@import "templates/case_studies";